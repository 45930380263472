<template>
    <div class="columns is-centered is-mobile" style="height: 83vh">
        <div class="column is-two-thirds-tablet is-four-fifths-mobile mt-6">
            <vue-simple-markdown :source="src" />
        </div>
    </div>
</template>

<script>
export default {
    name: "About",
    components: {},
    data() {
        return {
            src: "",
        };
    },
    async created() {
        this.src = await fetch("/ack.md").then((response) => {
            let markdown = response.text();
            return markdown;
        });
        console.log(this.src);
    },
};
</script>
<style lang="scss">
</style>
